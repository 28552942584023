import find from 'lodash/find'

/**
 * Enum for Task Types.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const dashboardPeriodTypesEnum = Object.freeze({
  WEEKLY: { key: 'WEEKLY', value: 'WEEKLY', text: 'Week' },
  MONTHLY: { key: 'MONTHLY', value: 'MONTHLY', text: 'Month' },
  YEARLY: { key: 'YEARLY', value: 'YEARLY', text: 'Year' },
  CUSTOM: { key: 'CUSTOM', value: 'CUSTOM', text: 'Custom' },
})

function getDashboardPeriodTypesEnum(predicate, predicateKey = 'key') {
  const result = find(dashboardPeriodTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getDashboardPeriodTypesEnumList() {
  const options = []
  Object.keys(dashboardPeriodTypesEnum).forEach(key => {
    options.push({
      value: dashboardPeriodTypesEnum[key].value,
      text: dashboardPeriodTypesEnum[key].text,
    })
  })
  return options
}

export default dashboardPeriodTypesEnum

export {
  getDashboardPeriodTypesEnum,
  getDashboardPeriodTypesEnumList,
}
