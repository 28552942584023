/* eslint-disable lines-between-class-members */
import dashboardPeriodTypesEnum from '@/enums/dashboardPeriodTypes.enum'
import BaseSearchFilter from './BaseSearchFilter'

export class AmazonSalesFilter extends BaseSearchFilter {
  /** @type {string} */
  #channel = ''
  #status = ''
  #marketplace = ''
  #isBusinessOrder = ''
  #detailedDisposition = ''
  #dashboardPeriodType = ''
  constructor() {
    super()
    this.#channel = this.channel
    this.#status = this.status
    this.#isBusinessOrder = this.isBusinessOrder
    this.#marketplace = this.marketplace
    this.#detailedDisposition = this.detailedDisposition
    this.#dashboardPeriodType = this.dashboardPeriodType
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.channel = 'ALL'
    this.status = 'ALL'
    this.isBusinessOrder = 'ALL'
    this.marketplace = 'ALL'
    this.detailedDisposition = 'ALL'
    this.dashboardPeriodType = dashboardPeriodTypesEnum.MONTHLY.value
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      channel: this.channel,
      status: this.status,
      isBusinessOrder: this.isBusinessOrder,
      marketplace: this.marketplace,
      detailedDisposition: this.detailedDisposition,
      dashboardPeriodType: this.dashboardPeriodType,
    }
    return filter
  }

  /** @returns {string} */
  get marketplace() {
    return this.#marketplace
  }
  /** @param {string} value */
  set marketplace(value) {
    this.#marketplace = value
  }
  /** @returns {string} */
  get channel() {
    return this.#channel
  }
  /** @param {string} value */
  set channel(value) {
    this.#channel = value
  }
  /** @returns {string} */
  get status() {
    return this.#status
  }
  /** @param {string} value */
  set status(value) {
    this.#status = value
  }
  /** @returns {string} */
  get isBusinessOrder() {
    return this.#isBusinessOrder
  }
  /** @param {string} value */
  set isBusinessOrder(value) {
    this.#isBusinessOrder = value
  }
  /** @returns {string} */
  get detailedDisposition() {
    return this.#detailedDisposition
  }
  /** @param {string} value */
  set detailedDisposition(value) {
    this.#detailedDisposition = value
  }
  /** @returns {string} */
  get dashboardPeriodType() {
    return this.#dashboardPeriodType
  }
  /** @param {string} value */
  set dashboardPeriodType(value) {
    this.#dashboardPeriodType = value
  }
}
export default new AmazonSalesFilter()
