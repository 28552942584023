<template>
  <div class="w-100">
    <div class="d-flex flex-row justify-content-start" :class="cssClass">
      <div style="margin-right: 0.625rem" class="align-self-center">
        <light-box :items="catalogItemInfo.imageItems" :index="index" :effect="'fade'" @close="index = null" />
        <div class="cursor-pointer" @click="showImage">
          <p-lazy-img width="75px" height="75px" :src="catalogItemInfo.imageUrl || defaultImg" />
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row justify-content-start">
          <div v-b-tooltip.hover="{ variant: 'info' }" :title="catalogItemInfo.title" class="align-self-center flex-grow-1">
            <copy-clipboard :text="catalogItemInfo.title ? catalogItemInfo.title : '-'" />
            <span class="dx-product-title text-info" role="button" @click="openProductDetails(catalogItemInfo.asin)">
              {{ getTitle(catalogItemInfo.title) }}
            </span>
          </div>
          <div v-if="catalogItemInfo.notes">
            <div :id="`info-note-${id}`" class="h3 mb-0 align-self-center" @mouseenter="toggleWithAnimation" @mouseleave="toggleWithAnimation">
              <b-icon
                icon="exclamation-circle-fill"
                variant="warning"
                class="hover-cursor-pointer"
                :animation="iconAnimation"
                @click="doCopy(catalogItemInfo.notes)"
              />
            </div>
            <dx-tooltip
              :target="`#info-note-${id}`"
              :position="'top'"
              :animation="animationConfig"
              :visible="withAnimationVisible"
              :close-on-outside-click="false"
            >
              {{ catalogItemInfo.notes }}
            </dx-tooltip>
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div v-if="itemInfoVisible" class="col-5">
            <copy-clipboard :text="orderNumber ? orderNumber : '-'" />
            Order#: {{ orderNumber ? orderNumber : '-' }}
          </div>
          <div class="col-7">
            <copy-clipboard :text="catalogItemInfo.upc ? catalogItemInfo.upc : '-'" />
            <span class="mr-half">UPC:</span>
            <a target="_blank" :href="`https://www.barcodelookup.com/${catalogItemInfo.upc}`">
              {{ catalogItemInfo.upc ? catalogItemInfo.upc : '-' }}
            </a>
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start align-items-center">
          <div v-if="itemInfoVisible" class="col-5">
            <copy-clipboard :text="trackingNumber? trackingNumber : '-'" />
            Track#: {{ trackingNumber ? trackingNumber : '-' }}
          </div>
          <div class="col-7">
            <span>
              <copy-clipboard :text="catalogItemInfo.asin ? catalogItemInfo.asin : '-'" />
            </span>
            <span class="mr-half">ASIN:</span>
            <a :href="`https://www.amazon.com/gp/product/${catalogItemInfo.asin}`" target="_blank" title="Show on Amazon">
              <span>
                {{ catalogItemInfo.asin ? catalogItemInfo.asin : '-' }}
              </span>
              <img height="24" width="24" :src="require('@/assets/images/svg/ecommerce/amazon.svg')" alt="">
            </a>
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div v-if="itemInfoVisible" class="col-5">
            <copy-clipboard :text="palletId? palletId : '-'" />
            Pallet Id: {{ palletId ? palletId : '-' }}
          </div>
          <div v-if="itemInfoVisible" class="col-7">
            <copy-clipboard :text="catalogItemInfo.fnsku ? catalogItemInfo.fnsku : '-'" />
            FNSKU:
            <b-dropdown
              v-if="isPrintable"
              split
              size="sm"
              split-variant="secondary"
              variant="secondary"
              :text="catalogItemInfo.fnsku"
              boundary="window"
              @click="onClickPrintFnksuLabel(catalogItemInfo.fnsku, 1)"
            >
              <b-dropdown-form style="width:150px !important;padding-top: 0.125rem !important; padding-bottom: 0.125rem !important;">
                <b-input-group size="sm">
                  <b-form-input v-model="printCount" type="number" autofocus />
                  <b-input-group-append>
                    <b-button size="sm" variant="warning" @click="onClickPrintFnksuLabel(catalogItemInfo.fnsku)">
                      <b-icon-printer-fill scale="1" aria-label="Help" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-dropdown-form>
          </b-dropdown>
            <span v-else>
              {{ catalogItemInfo.fnsku }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div v-if="itemInfoVisible" class="col-5">
            Exp Date: {{ formatedDate(expirationDate) }}
          </div>
          <div v-if="msku !== 'hide'" class="col-7">
            <copy-clipboard :text="catalogItemInfo.msku ? catalogItemInfo.msku : '-'" />
            MSKU: {{ catalogItemInfo.msku ? catalogItemInfo.msku : '-' }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div v-if="itemInfoVisible" class="col-5">
            Uploaded At: {{ batchUploadedAt ? formatedDate(batchUploadedAt) : '-' }}
          </div>
          <div v-if="itemInfoVisible" class="col-7">
            AMZ Rank: {{ catalogItemInfo.rank }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div v-if="itemInfoVisible" class="col-5">
            Purcased At: {{ purchaseDate ? formatedDate(purchaseDate) : '-' }}
          </div>
          <div v-if="itemInfoVisible" class="col-7">
            Supplier: {{ catalogItemInfo.supplier ? catalogItemInfo.supplier : '-' }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div v-if="itemInfoVisible" class="col-5">
            Sale/Cost: {{ catalogItemInfo.salePrice ? `$${catalogItemInfo.salePrice}` : '-' }} / {{ catalogItemInfo.costPrice ? `$${catalogItemInfo.costPrice}` : '-' }}
          </div>
          <div v-if="itemInfoVisible" class="col-5">
            Batch: {{ batchName ? batchName : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usePrinter from '@/libs/printer/print'
import CoolLightBox from 'vue-cool-lightbox'
import CatalogItem from '@/libs/app/catalog-item'
import truncate from 'lodash/truncate'
import { formatedDateStr } from '@/@core/utils/dateHelper'
import { DxTooltip } from 'devextreme-vue/tooltip'
import { useClipboard } from '@vueuse/core'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
    'dx-tooltip': DxTooltip,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: '',
    },
    catalogItem: {
      type: Object,
      required: false,
      default: () => {},
    },
    priceList: {
      type: [Object, Array],
      required: false,
      default: () => {},
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    notes: {
      type: String,
      required: false,
      default: '',
    },
    asin: {
      type: String,
      required: false,
      default: '',
    },
    orderNumber: {
      type: String,
      required: false,
      default: '',
    },
    fnsku: {
      type: String,
      required: false,
      default: '',
    },
    msku: {
      type: [Number, String],
      required: false,
      default: '',
    },
    upc: {
      type: String,
      required: false,
      default: '',
    },
    rank: {
      type: [Number, String],
      required: false,
      default: null,
    },
    productType: {
      type: String,
      required: false,
      default: '',
    },
    imageUrl: {
      type: String,
      required: false,
      default: '',
    },
    largeImageUrl: {
      type: String,
      required: false,
      default: '',
    },
    trackingNumber: {
      type: String,
      required: false,
      default: '',
    },
    palletId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    expirationDate: {
      type: String,
      required: false,
      default: '',
    },
    supplier: {
      type: String,
      required: false,
      default: '',
    },
    costPrice: {
      type: [Number, String],
      required: false,
      default: '',
    },
    salePrice: {
      type: [Number, String],
      required: false,
      default: '',
    },
    isPrintable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    cssClass: {
      type: String,
      required: false,
      default: '',
    },
    isTruncated: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    purchaseDate: {
      type: String,
      required: false,
      default: '',
    },
    batchUploadedAt: {
      type: String,
      required: false,
      default: '',
    },
    batchName: {
      type: String,
      required: false,
      default: '',
    },
    itemInfoVisible: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup() {
    const { copy } = useClipboard()
    const { printFnskuLabel } = usePrinter()

    return {
      copy,
      printFnskuLabel,
    }
  },
  data() {
    return {
      index: null,
      printCount: 1,
      withAnimationVisible: false,
      iconAnimation: 'throb',
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0.1,
            opacity: 0,
          },
          to: {
            scale: 1,
            opacity: 1,
          },
        },
        hide: {
          type: 'pop',
          from: {
            scale: 1,
            opacity: 1,
          },
          to: {
            scale: 0.1,
            opacity: 0,
          },
        },
      },
      defaultImg: defaultImageUrl,
    }
  },
  computed: {
    catalogItemInfo() {
      const catalogItemIns = new CatalogItem()
      catalogItemIns.catalogItem = this.catalogItem
      catalogItemIns.priceList = this.priceList
      const imageItems = [this.largeImageUrl]
      return {
        title: this.title ? this.title : catalogItemIns.getTitle(),
        notes: this.notes,
        asin: this.asin ? this.asin : catalogItemIns.getAsin(),
        fnsku: this.fnsku,
        msku: this.msku,
        upc: this.upc,
        rank: this.rank ? this.rank : catalogItemIns.getRank(),
        productType: this.productType ? this.productType : catalogItemIns.getProductType(),
        imageUrl: this.imageUrl ? this.imageUrl : catalogItemIns.getImageLink(),
        imageItems: imageItems,
        costPrice: this.costPrice,
        salePrice: this.salePrice,
        supplier: this.supplier,
      }
    },
  },
  methods: {
    formatedDate(date) {
      return formatedDateStr(date, 'MM/DD/YYYY')
    },
    toggleWithAnimation() {
      this.iconAnimation = this.withAnimationVisible ? 'throb' : ''
      this.withAnimationVisible = !this.withAnimationVisible
    },
    getTitle(title) {
      return this.isTruncated ? truncate(title, { length: 100 }) : title
    },
    async onClickPrintFnksuLabel(data, count) {
      const exprDate = formatedDateStr(this.expirationDate, 'MM/DD/YYYY')
      const copies = count || parseInt(this.printCount, 10)
      const payload = {
        barcode: data,
        title: this.catalogItemInfo.title,
        expirationDate: exprDate,
        copies: copies,
      }
      this.printCount = 1
      await this.printFnskuLabel(payload)
    },
    showImage() {
      this.index = 0
    },
    openProductDetails(asin) {
      let param = ''
      let isAsin = false
      if (this.id) {
        param = this.id
        isAsin = false
      } else {
        param = asin
        isAsin = true
      }
      this.$emit('on-emit-catalog-item-info', { param: param, isAsin: isAsin })
    },
    doCopy(message) {
      this.copy(message)
    },
  },
}
</script>

<style lang="scss" scoped>
  .hover-cursor-pointer {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }
</style>
@/@core/utils/dateHelper
