import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const amzDispositionsEnum = Object.freeze({
  SELLABLE: { key: 'SELLABLE', value: 'SELLABLE', text: 'Sellable' },
  UNSELLABLE: { key: 'UNSELLABLE', value: 'UNSELLABLE', text: 'Unsellable' },
})

function getAmzDispositionsEnum(predicate, predicateKey = 'key') {
  const result = find(amzDispositionsEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getAmzDispositionsList() {
  const options = []
  Object
    .keys(amzDispositionsEnum)
    .map(key => options.push(
      {
        value: amzDispositionsEnum[key].value,
        text: amzDispositionsEnum[key].text,
      },
    ))
  return options
}

export default amzDispositionsEnum

export {
  getAmzDispositionsEnum,
  getAmzDispositionsList,
}
