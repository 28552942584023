<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 card px-half">
        <dx-data-grid
          id="amazonRemovalsGrid"
          ref="amazonRemovalGridRef"
          height="calc(100vh - 200px)"
          :data-source="amazonRemovalsDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @option-changed="onOptionChanged"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            :text="filter.q"
            placeholder="Search..."
          />
          <dx-remote-operations :paging="true" :filtering="true" :sorting="true" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-column data-field="title" cell-template="titleCellTemplate" />
          <template #titleCellTemplate="{data}">
            <item-info
              :id="data.data.productId"
              :title="data.data.title"
              :asin="data.data.asin"
              :upc="data.data.upc"
              :msku="data.data.msku"
              :image-url="data.data.smallImage"
              :large-image-url="data.data.largeImage"
              :rank="data.data.amzRank"
              :is-printable="false"
              :is-truncated="false"
              :item-info-visible="false"
              @on-emit-catalog-item-info="openProductDetails"
            />
          </template>
          <dx-column data-field="trackingNumber" :width="220" />
          <dx-column data-field="shippedQuantity" caption="Quantity" :width="100" cell-template="quantityTemplate" />
          <template #quantityTemplate="{data}">
            <edit-removal-qty :row="data" @on-emit-quantity-updated="searchByFilter" />
          </template>
          <dx-column data-field="itemCost" :width="100" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="requestDate" width="140" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column data-field="shipmentDate" width="140" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column data-field="creationTime" caption="Received At" width="140" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column data-field="disposition" :width="140" cell-template="dispositionTemplate" />
          <dx-column data-field="removalOrderType" :width="180" cell-template="removalOrderTemplate" />
          <dx-column data-field="invoiceId" caption="Invoice" :width="100" />
          <dx-column data-field="notes" :width="60" cell-template="notesTemplate" />
          <dx-column data-field="accountNo" caption="Acc #" />
          <dx-column data-field="companyName" caption="Company" />
          <dx-column data-field="storeName" caption="Store" />
          <template #notesTemplate="{data}">
            <div v-if="data.value" class="d-block text-center">
              <i v-b-tooltip.d800.hover.top.v-secondary="data.value" role="button" class="icon bi-stickies-fill text-warning" />
            </div>
          </template>
          <template #dispositionTemplate="{data}">
            <div v-if="data.value" :class="resolveDisposition(data.value)" class="d-block">
              {{ data.value }}
            </div>
          </template>
          <template #statusTemplate="{data}">
            <div v-if="data.value" :class="resolveStatus(data.value)">
              {{ data.value }}
            </div>
          </template>
          <template #removalOrderTemplate="{data}">
            <div v-if="data.value" :class="resolveRemovalOrder(data.value)">
              {{ data.value }}
            </div>
          </template>
          <template #toolbarFilters>
            <div class="d-flex">
              <div class="mr-half">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div v-if="false" class="mr-half">
                <dx-util-select-box
                  v-model="filter.marketplace"
                  :data-source="marketplaces"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div v-if="false" class="mr-half">
                <dx-util-select-box
                  v-model="filter.channel"
                  :data-source="fulfillmentMethods"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <date-selection default-date="current_month" @setdates="onDateSelection" />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isProductDetailsVisible"
      @close="isProductDetailsVisible=false,selectedProductId=null"
    />
    <dx-util-popover
      :target="hoveredItem"
      position="right"
      :defer-rendering="false"
      :close-on-outside-click="true"
      :show-title="true"
      title="Cost Details"
      :width="400"
      :visible="isPopoverVisible"
      :show-close-button="true"
      @hiding="closePopover"
    >
      <template>
        <div>
          <cost-snapshot :cost-details="costDetails" />
        </div>
      </template>
    </dx-util-popover>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ItemInfo from '@/views/apps/outbound/fba-shipments/components/ItemInfo.vue'
import { getMarketplaceList } from '@/enums/marketplaceEnum'
import { getFulfillmentMethodList } from '@/enums/fulfillmentType.enum'
import { AmazonRemovalsDataSource } from './amazonRemovalsStore'
import amzDispositionsEnum from '@/enums/amzDispositions.enum'
import EditRemovalQty from '@/views/apps/reports/amazon-reports/components/EditRemovalQty.vue'

// import Pager from '@core/dev-extreme/utils/pager'
// import ReportDateRangeEnum from '@/enums'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    EditRemovalQty,
    'cost-snapshot': () => import('./CostSnapshot.vue'),
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'light-box': () => import('vue-cool-lightbox'),
    'item-info': ItemInfo,
    'date-selection': DateSelection,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      marketplaces: getMarketplaceList(),
      fulfillmentMethods: getFulfillmentMethodList(),
      amazonRemovalsDataSource: AmazonRemovalsDataSource,
      filter: {
        marketplace: 'ALL',
        channel: 'ALL',
        q: '',
        beginDate: null,
        endDate: null,
      },
      defaultImg: defaultImageUrl,
      selectedProductId: null,
      isProductDetailsVisible: false,
      images: [],
      index: null,
      storeId: null,
      reportItem: '',
      hoveredItem: '',
      isPopoverVisible: false,
      costDetails: {
        totalCOGS: null,
        fbaFee: null,
        referralFee: null,
        mfnShipping: null,
        prepFee: null,
        planFee: null,
        shippingFee: null,
        miscFee: null,
      },
      selectedRow: {},
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      return this.$refs.amazonRemovalGridRef.instance
    },
    dataSource() {
      return this.dataGrid.getDataSource()
    },
  },
  created() {
    this.getStores()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    openProductDetails(e) {
      this.selectedProductId = e.param
      this.isProductDetailsVisible = true
    },
    loadData() {
      AmazonSalesFilter.setDefaultFilters()
      AmazonSalesFilter.beginDate = this.beginDate
      AmazonSalesFilter.endDate = this.endDate
      const filters = AmazonSalesFilter.getFilters()
      AmazonRemovalsDataSource.searchValue(filters)
    },
    searchByFilter() {
      AmazonSalesFilter.storeId = this.selectedStore
      AmazonSalesFilter.beginDate = this.filter.beginDate
      AmazonSalesFilter.endDate = this.filter.endDate
      AmazonSalesFilter.marketplace = this.filter.marketplace
      AmazonSalesFilter.channel = this.filter.channel
      AmazonSalesFilter.q = this.filter.q
      const filters = AmazonSalesFilter.getFilters()
      AmazonRemovalsDataSource.searchValue(filters)
      AmazonRemovalsDataSource.reload()
    },
    onDateSelection(e) {
      this.filter.beginDate = e.beginDate
      this.filter.endDate = e.endDate
      this.searchByFilter()
    },
    resolveDisposition(disposition) {
      if (disposition === amzDispositionsEnum.SELLABLE.value) {
        return 'badge badge-success'
      }
      if (disposition === amzDispositionsEnum.UNSELLABLE.value) {
        return 'badge badge-danger'
      }
      return 'badge badge-primary'
    },
    resolveStatus(status) {
      if (status === 'Return') {
        return 'badge badge-light-danger'
      }
      return 'badge badge-light-primary'
    },
    resolveRemovalOrder(type) {
      if (type === 'Liquidations') {
        return 'badge badge-warning'
      }
      return 'badge badge-primary'
    },
    resolveByValue(value) {
      const profit = parseInt(value, 10)
      if (profit >= 0) {
        return 'badge badge-light-success'
      }
      return 'badge badge-light-danger'
    },
    togglePopover(data, costType) {
      const rowId = data.data.id
      this.hoveredItem = `#cost-${rowId}`
      const payload = {
        fbaFee: data.data.fbafee,
        referralFee: data.data.referralFee,
        mfnShipping: data.data.mfnShipping,
        prepFee: data.data.prepFee,
        planFee: data.data.planFee,
        shippingFee: data.data.shippingFee,
        miscFee: data.data.miscFee,
        costType: costType,
      }

      this.isPopoverVisible = true
      this.costDetails = payload
    },
    closePopover() {
      this.isPopoverVisible = false
      this.costDetails = {
        totalCOGS: 0,
        fbaFee: 0,
        referralFee: 0,
        prepFee: 0,
        planFee: 0,
        shippingFee: 0,
        miscFee: 0,
        mfnShipping: 0,
      }
    },
    // DATAGRID METHODS
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        this.dataSource.pageIndex(0)
      }
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filter.q = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filter.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filter.q = ''
            this.searchByFilter()
          }
        }
      }
    },
    async onDownloadReport(e) {
      AmazonSalesFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      const filters = AmazonSalesFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await amazonReportService.removalsByQuery(pageableQuery, filters)
      const data = response.content
      this.onDownloadExportData(data)
    },
    onDownloadReportSelected() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Removals_${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 60 },
        { header: 'Marketplace', key: 'marketplace', width: 20 },
        { header: 'Channel', key: 'channel', width: 20 },
        { header: 'Request Date', key: 'requestDate', width: 20 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'MSKU', key: 'msku', width: 14 },
        { header: 'Quantity', key: 'shippedQuantity', width: 14 },
        { header: 'Item Cost', key: 'itemCost', width: 14 },
        { header: 'Disposition', key: 'disposition', width: 20 },
        { header: 'Removal Order Type', key: 'removalOrderType', width: 20 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.marketplace,
          item.channel,
          item.requestDate,
          item.asin,
          item.upc,
          item.msku,
          item.shippedQuantity,
          item.totalFees,
          item.disposition,
          item.removalOrderType,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Amazon_Removals_${fileDate}.xlsx`,
        )
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Report',
          onItemClick: arg => {
            if (arg) {
              //
            }
            if (arg.itemData.id === 'all') {
              this.onDownloadReport()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadReportSelected()
            }
          },
        },

        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.dataSource.reload()
          },
        },
        location: 'after',
      })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
