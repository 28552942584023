<template>
  <div class="d-flex">
    <div style="padding-top: 2px; padding-bottom: 2px;">
      <dx-util-button
        v-if="selectedDateRange === 'custom'"
        icon="icon bi-calendar-x"
        type="danger"
        @click="openDateRangeSelection"
      />
    </div>
    <dx-util-date-box
      v-if="selectedDateRange === 'custom'"
      v-model="beginDate"
      class="mx-half"
      placeholder="Begin Date"
      :max="endDate"
      type="date"
      date-serialization-format="yyyy-MM-dd"
      @value-changed="onDateRangeSelected"
    />
    <dx-util-date-box
      v-if="selectedDateRange === 'custom'"
      v-model="endDate"
      class="mx-half"
      placeholder="End Date"
      :min="beginDate"
      type="date"
      date-serialization-format="yyyy-MM-dd"
      @value-changed="onDateRangeSelected"
    />
    <dx-util-select-box
      v-else
      v-model="selectedDateRange"
      class="mx-half"
      :data-source="dateRangeOptions"
      placeholder="Select Date"
      display-expr="text"
      value-expr="value"
      @value-changed="onDateRangeSelected"
    />
  </div>
</template>

<script>
import { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum'
import { ref, onMounted } from '@vue/composition-api'
import moment from 'moment'

export default {
  emits: ['setdates'],
  // eslint-disable-next-line vue/require-prop-types
  props: ['defaultDate'],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const selectedDateRange = ref('')
    function openDateRangeSelection() {
      selectedDateRange.value = 'last90Days'
    }

    const beginDate = ref('')
    const endDate = ref('')

    function onDateRangeSelected(e) {
      const selected = e.value
      emit('changed-dates', selected)
      const today = moment()

      switch (selected) {
        case 'today':
          beginDate.value = today.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break

        case 'current_week': {
          beginDate.value = today.startOf('week').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.endOf('week').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'current_month': {
          beginDate.value = today.startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.endOf('month').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'current_quarter': {
          beginDate.value = today.startOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.endOf('quarter').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'current_year': {
          beginDate.value = today.startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.endOf('year').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'last7Days': {
          beginDate.value = today.subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.add(8, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'last30Days': {
          beginDate.value = today.subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.add(31, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'last90Days': {
          beginDate.value = today.subtract(90, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.add(91, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        case 'last365Days': {
          beginDate.value = today.subtract(365, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          endDate.value = today.add(366, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }

        default:
          break
      }
      emit('setdates', {
        beginDate: beginDate.value, endDate: endDate.value,
      })
    }

    onMounted(() => {
      selectedDateRange.value = props.defaultDate
    })

    return {
      customDateSelection: ref(false),
      beginDate,
      endDate,
      dateRangeOptions: getPredefinedDateRangesList(),
      selectedDateRange,
      openDateRangeSelection,
      onDateRangeSelected,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
