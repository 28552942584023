<template>
  <div class="">
    <div v-if="!editable" class="d-flex justify-content-around">
      <span>
        {{ row.value }}
      </span>
      <p-icon role="button" name="bi-pencil-square" class="text-primary" @click.native="editable = true" />
    </div>
    <dx-util-number-box
      v-else
      :ref="`textbox-quantity-${row.data.id}`"
      v-model="newQuantity"
      :min="0"
      @content-ready="setEditable"
      @value-changed="updateQuantityInForm"
      @focus-in="onFocusInUpdate(row)"
      @focus-out="editable=false"
      @key-down="onKeyDown($event, row)"
      @enter-key="onEnterKey"
    >
      <dx-text-box-button
        :name="`quantity-${row.data.id}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `quantity-${row.data.id}` },
          onClick: () => onUpdateQuantity(row)
          }"
        location="after"
      />
    </dx-util-number-box>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editable: false,
      newQuantity: null,
    }
  },
  mounted() {
    this.newQuantity = this.row.value
  },
  methods: {
    updateQuantityInForm(e) {
      this.newQuantity = e.value
    },
    setEditable(e) {
      e.component.focus()
      this.onFocusInUpdate(this.row)
    },
    async onUpdateQuantity(row) {
      if (this.newQuantity > this.row.value) {
        Notify.warning('You can only decrease the quantity here. To increase the quantity, please go to receive inventory.')
        return
      }
      if (!row.data.id) return
      const item = { ...row.data }
      await amazonReportService.updateRemovalQuantity(item.id, this.newQuantity)
      this.editable = false
      this.$emit('on-emit-quantity-updated')
    },
    onFocusInUpdate() {
      this.newQuantity = this.row.value
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (this.editable && e.event.which === escapeKey) {
        this.editable = false
      }
    },
    onEnterKey() {
      this.onUpdateQuantity(this.row)
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
